import React, {useEffect, useState} from 'react';
import "./cookies-dialog.scss";
import {connect} from "react-redux";

function CookiesDialog({hideCookiesDialog}) {
    const [acceptButtonText, setAcceptButtonText] = useState('Accept');
    const [declineButtonText, setDeclineButtonText] = useState('Decline');

    const saveUserChoice=(choice)=>{
        localStorage.setItem('userCookies',JSON.stringify({"cookies":choice}));
        hideCookiesDialog();
    }

    const hideIfCookiesAcceptedOrDeclined=()=>{
        if (acceptButtonText !== 'Accept' || declineButtonText !== 'Decline'){
            hideCookiesDialog();
        }
    }

    useEffect(() => {
        const userCookies = localStorage.getItem('userCookies');

        if (userCookies){
            const value = JSON.parse(userCookies).cookies;

            if (value === 'Accepted'){
                setAcceptButtonText('Accepted');
            }
            else
                if (value === 'Declined'){
                    setDeclineButtonText('Declined');
                }
        }
    }, []);

    return (
        <>
            <div className="ModalDialogWrapper">
                <div className="Backdrop" onClick={hideIfCookiesAcceptedOrDeclined}/>
                <div className="Modal">
                    <div className="ModalContent">
                        <div className="ModalText">
                            <p>Please read this Cookie Policy alongside our <a href='/legal/privacy' class='link'>Privacy Policy</a>.</p>
                            <p>Our website and apps store cookies on your computer and mobile device. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our <a href='/legal/cookie-notice' class='link'>Cookie Policy</a>.</p>
                            <p>If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</p>
                            <p>I want to change my cookie-preference</p>
                        </div>
                        <div className="ButtonsContainer">
                            <button className="DialogButton Accept" type="button" onClick={()=>saveUserChoice("Accepted")}><span className="DialogButtonText Accept">{acceptButtonText}</span></button>
                            <button className="DialogButton Decline" type="button" onClick={()=>saveUserChoice("Declined")}><span className="DialogButtonText Decline">{declineButtonText}</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        cookiesDialogVisible: state.cookiesDialogVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCookiesDialog: () => dispatch({type: `SHOW_DIALOG_COOKIES`}),
        hideCookiesDialog: () => dispatch({type: `HIDE_DIALOG_COOKIES`}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiesDialog);

