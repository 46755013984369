import React, { useState, useEffect, useRef } from "react"
import "./header.scss"
import logo from "../../assets/img/logo-tm.svg";
import small from "../../assets/img/imago@2x.svg"
import iconMenu from "../../assets/img/icon-menu.svg"
import logoFooter from "../../assets/img/logo-tm-white.svg"
import Close from "../../assets/img/icon-close.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = ({ toggle }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = () => setIsPopupVisible(!isPopupVisible);

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => setSticky(window.scrollY && ref && ref.current && ref.current.getBoundingClientRect().top === 0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`HeaderWrapper ${isSticky ? "Sticky" : ""}`} ref={ref}>
      <header className={`HeaderContainer`}>
        <div className="Logo">
          <AnchorLink className="Item" to="/#Home" title="Home">
          <img className="Small" src={small} alt={"AfriMed Elephant Icon"}/>
          <img className="Big" src={logo} alt={"AfriMed Official Logo"}/>
          </AnchorLink>
        </div>
        <div className="Menu">
          <img className="IconMenu" src={iconMenu} alt="Menu Icon" onClick={togglePopup}/>
          <div className="Links">
            <AnchorLink className="Item" to="/#Home" title="Home">Home</AnchorLink>
            <AnchorLink className="Item" to="/#HowItWorks" title="How It Works">How It Works</AnchorLink>
            <a href="#" className="Item" onClick={toggle()}>Contact Us</a>
          </div>
          <a className="DownloadButton" onClick={toggle(true)}>I'm interested</a>
        </div>
      </header>
      </div>
      {isPopupVisible && <div className="PopupMenu">
        <img className="Close" src={Close} alt="Close" onClick={togglePopup}/>
        <img className="Logo" src={logoFooter} alt="Afrimed Elephant Icon"/>
        <div className="Links" onClick={togglePopup}>
          <AnchorLink className="Item" to="/#Home" title="Home">Home</AnchorLink>
          <AnchorLink className="Item" to="/#HowItWorks" title="How It Works">How It Works</AnchorLink>
          <div className="Item" onClick={toggle()}>Contact Us</div>
        </div>
      </div>}
    </>
  )
}

export default Header
