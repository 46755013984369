import {connect} from "react-redux";
import CookiesDialog from "../cookies-dialog/cookies-dialog";
import React, {useEffect} from "react";

function Layout({cookiesDialogVisible, children}) {
    if (typeof window !== 'undefined' && window.document) {
        const gaProperty = 'G-6PD37M8584';
        const disableGaStr = 'ga-disable-' + gaProperty;
        if (window.document.cookie.indexOf(disableGaStr + '=true') > -1) {
            window[disableGaStr] = true;
        }
    }


    return (
        <>
            {cookiesDialogVisible && <CookiesDialog/>}
            {children}
        </>
    )
}

const mapStateToProps = state => {
    return {
        cookiesDialogVisible: state.cookiesDialogVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCookiesDialog: () => dispatch({type: `SHOW_DIALOG_COOKIES`}),
        hideCookiesDialog: () => dispatch({type: `HIDE_DIALOG_COOKIES`}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
