import React, { useState } from "react"
import axios from 'axios';
import * as yup from 'yup';
import Close from "../../assets/img/icon-close.svg"
import Bkg from "../../assets/img/bkg-contact-us.svg"
import "./contact.scss"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
  email: yup.string('Field required').email('Invalid email').required('Field required'),
  firstName: yup.string('Field required').required('Field required'),
  lastName: yup.string('Field required').required('Field required'),
  message: yup.string('Field required').required('Field required'),
  interested: yup.bool().oneOf([true], 'Consent must be checked for us to contact you.')
});

const Contact = ({ toggle = null , isInterested = false}) => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: isInterested ? "I'm interested." : '',
    interested: isInterested
  });

  const [formErrors, setFormErrors] = useState({});

  const onChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  }

  const [isSending, setIsSending] = useState(false);

  const onSubmit = async () => {
    setIsSending(true);
    try {
      const isValid = await schema.isValid(formState);

      if (!isValid) {
        try {
          await schema.validate(formState);
        } catch (e) {
          setFormErrors({
            [e.path]: e.message
          });
        }

      } else {
        const result = await axios.post('https://afrimedtelehealth.com/api/v1/contact', formState);

        if (result.data.success) {
          toast('Your message has been sent.', {
            type: 'success'
          })
          toggle()();
        }
      }
    } catch (e) {
      toast(e.toString(), {
        type: 'error'
      });
    } finally {
      setIsSending(false);
    }
  }

  return <div className="ContactContainer">
    <img className="Bkg" src={Bkg} alt={"bkg"}/>
    <div className="Header">
      <div className="Title">Contact Us</div>
      <img src={Close} alt="Close" onClick={toggle()}/>
    </div>
    <div className="Content">
      <div className="Row">
        <div className="InputGroup">
          <label>First Name</label>
          <input name={'firstName'} onChange={onChange} value={formState['firstName']} type="text"/>
          {formErrors['firstName'] && <div className={'Error'}>{formErrors['firstName']}</div>}
        </div>
        <div className="InputGroup">
          <label>Last Name</label>
          <input name={'lastName'} onChange={onChange} value={formState['lastName']} type="text"/>
          {formErrors['lastName'] && <div className={'Error'}>{formErrors['lastName']}</div>}
        </div>
      </div>
      <div className="Row">
        <div className="InputGroup">
          <label>Email</label>
          <input name={'email'} onChange={onChange} value={formState['email']} type="text"/>
          {formErrors['email'] && <div className={'Error'}>{formErrors['email']}</div>}
        </div>
        <div className="InputGroup">
          <label>Message</label>
          <textarea name={'message'} onChange={onChange} value={formState['message']} placeholder={"How can we help?"}/>
          {formErrors['message'] && <div className={'Error'}>{formErrors['message']}</div>}
        </div>
      </div>
      <div className="Row">
        <div className="InputGroup"/>
        <div className="InputGroup">
          <div>
            <input id="c1" type="checkbox" onChange={() => onChange({
              target: {
                name: 'interested',
                value: !formState['interested']
              }
            })} checked={formState['interested']} />
            <label htmlFor="c1">I consent to be contacted by AfriMed</label>
            {formErrors['interested'] && <div className={'Error'}>{formErrors['interested']}</div>}
          </div>
        </div>
      </div>
      <button onClick={onSubmit} disabled={isSending}>
        {isSending ? 'Sending...' : 'Send'}
      </button>
    </div>
  </div>
}

export default Contact
