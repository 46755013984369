import React from "react"
import logoFooter from "../../assets/img/logo-tm-white.svg"
import imago from "../../assets/img/imago@2x.svg"
import facebook from "../../assets/img/icon-fb.svg"
import twitter from "../../assets/img/icon-twitter.svg"
import linked from "../../assets/img/icon-linkedin.svg"
import instagram from "../../assets/img/icon-instagram.svg"
import "./footer.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import FooterLinks from "../footer-links/footer-links";

const Footer = ({ toggleContactUs, animation, isLegalPage }) => (
  <footer className={"FooterContainer " + (isLegalPage ? "margin-top-bigger":"")}>
    <div className="TopContainer">
      <div className="ContentWrapper">
        <div className="Download">
          <div className="Title">
            Start managing your healthcare
            today for absolutely free
          </div>
          <a className="Button" onClick={toggleContactUs(true)}>I'm interested</a>
        </div>
        <div className="Footer">
          <div className="Separation">
            <AnchorLink className="Item" to="/#Home" title="Home">
            <img className="Logo" src={imago} alt="AfriMed Elephant Icon"/>
            </AnchorLink>
          </div>
          <div className="Separation">
            <div className="Links">
              <AnchorLink className="Item" to="/#Home" title="Home">Home</AnchorLink>
              <AnchorLink className="Item" to="/#HowItWorks" title="How It Works">How It Works</AnchorLink>
              <a onClick={toggleContactUs()}>Contact Us</a>
            </div>
          </div>
          <div className="Separation">
            <div className="FollowUs">
              <div className="Title">Follow Us</div>
              <div className="Social">
                <a target="_blank" href="https://www.facebook.com/AfriMeds/">
                  <img src={facebook} alt={"Facebook"}/>
                </a>
                <a target="_blank" href="https://twitter.com/AfriMed1">
                  <img src={twitter} alt={"Twitter"}/>
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/49145139">
                  <img src={linked} alt={"Linked"}/>
                </a>
                <a target="_blank" href="https://www.instagram.com/afrimed.tech/">
                  <img src={instagram} alt={"Instagram"}/>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div className="BottomContainer">
      <FooterLinks/>
      <AnchorLink className="Item" to="/#Home" title="Home">
        <img className="Logo" src={logoFooter} alt="AfriMed Official Logo"/>
      </AnchorLink>
      <p>
        All rights reserved © {(new Date()).getFullYear()}
      </p>
      <p>
        AfriMed TeleHealth is a Swiss Healthtech start-up with it’s Africa HQ in Western Cape, registered in Cape Town, South Africa with registration number 2021/109214/07 and Tax Registration Number 9562021205
      </p>
      <p>
        <a
            target="blank"
            title="Company"
            registration=""
            href="https://www.govchain.co.za/verify-company?id=K2021109214">
          <img
              src="https://govchain-static.s3.amazonaws.com/logo/verification-badge-medium.png"
              width="80px"
              alt="Company"
              registration=""/>
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
