import React from "react"
import "./footer-links.scss"
import {Link} from "../../../.cache/gatsby-browser-entry";
import {connect} from "react-redux";

const FooterLinks = ({showCookiesDialog}) => {
    return (
        <>
            <div className="FooterLinksContainer">
                <Link className="Item" to="/legal/privacy" title="Privacy Notice">Privacy Notice</Link>
                <span className="Item" title="Cookies Preferences" onClick={showCookiesDialog}>Cookies Preferences</span>
                <Link className="Item" to="/legal/cookie-notice" title="Cookie Notice">Cookie Notice</Link>
                <Link className="Item" to="/legal/terms" title="Terms">Terms</Link>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        cookiesDialogVisible:state.cookiesDialogVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCookiesDialog: () => dispatch({type: `SHOW_DIALOG_COOKIES`}),
        hideCookiesDialog: () => dispatch({type: `HIDE_DIALOG_COOKIES`}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterLinks)


